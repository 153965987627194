[data-component="PackageCard"] {

  display: flex;
  flex-direction: column;
  background: var(--background-input);
  border-radius: var(--radius-lg);
  overflow: hidden;
  position: relative;

  &[data-mode="page"] {
    background: transparent;
    .package-avatar {
      padding: 0px;
      padding-top: var(--unit-xl);
    }
    .package-card-body {
      padding: 0px;
      .package-card-footer {
        margin-right: 0px;
      }
    }
  }

  .package-card-header {
    display: flex;
    align-items: center;
    gap: var(--unit-lg);
    position: absolute;
    top: var(--unit-sm);
    right: var(--unit-sm);
    background: var(--background-input);
    padding: var(--unit-sm) var(--unit-md);
    border-radius: var(--radius-lg);
    .package-card-rating, .package-card-activity {
      gap: var(--unit-md);
      svg {
        height: var(--unit-lg);
        width: var(--unit-lg);
        &.star {
          color: var(--orange-main);
          fill: currentColor;
        }
      }
    }
    .package-card-rating {
      display: flex;
      align-items: center;
      color: var(--color-disabled);
    }
    .vertical-divider {
      height: calc(var(--form-sm) - var(--unit-sm) * 2);
      top: var(--unit-sm);
      width: 1px;
      background: var(--outline-default);
    }
    .package-card-activity {
      display: flex;
      align-items: center;
      color: var(--color-disabled);
    }
    .package-card-type {
      display: flex;
      align-items: center;
      gap: var(--unit-sm);
      color: var(--color-tertiary);
      font-size: var(--font-size-sm);
      & > span {
        display: flex;
        align-items: center;
        gap: var(--unit-sm);
        .package-card-icon {
          color: var(--color-disabled);
          display: flex;
          opacity: 0.6;
          svg {
            height: var(--unit-lg);
            width: var(--unit-lg);
          }
        }
      }
    }
    .package-card-install-status {
      display: flex;
      align-items: center;
      gap: var(--unit-sm);
      color: var(--color-tertiary);
      font-size: var(--font-size-sm);
      svg {
        height: var(--unit-lg);
        width: var(--unit-lg);
        color: var(--green-light);
      }
    }
  }

  .package-avatar {
    max-width: 100%;
    padding: 0px var(--unit-lg);
    padding-top: var(--unit-lg);
    display: flex;
    align-items: flex-start;
    gap: var(--unit-md);
    .package-avatar-content {
      position: relative;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: var(--form-lg);
      width: var(--form-lg);
      color: var(--white);
      border-radius: var(--radius-lg);
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &[data-color="purple"] {
        background: var(--purple-dark);
      }
      &[data-color="blue"] {
        background: var(--blue-dark);
      }
      &[data-color="green"] {
        background: var(--green-dark);
      }
      &[data-color="orange"] {
        background: var(--orange-dark);
      }
      &[data-color="red"] {
        background: var(--red-dark);
      }
      &[data-color="blue"] {
        background: var(--blue-dark);
      }
      .package-avatar-placeholder {
        user-select: none;
        font-size: var(--font-size-xxl);
      }
    }
    .package-card-title {
      display: flex;
      flex-direction: column;
      padding-left: var(--unit-md);
      padding-top: var(--unit-sm);
      justify-content: center;
      overflow-x: hidden;
      & > div {
        display: flex;
        align-items: center;
      }
      .package-card-input {
        width: 200px;
        max-width: 100%;
        display: flex;
        align-items: center;
        gap: var(--unit-sm);
      }
      .package-card-display-name {
        font-size: var(--font-size-lg);
        font-weight: var(--font-weight-medium);
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: var(--unit-md);
        // & > a {
        //   overflow: hidden;
        //   text-overflow: ellipsis;
        //   white-space: nowrap;
        // }
        .package-card-version {
          padding: var(--unit) var(--unit-md);
          border-radius: var(--radius-lg);
          background: var(--background-secondary);
          font-size: var(--font-size-sm);
          color: var(--color-disabled);
        }
      }
      .package-card-description {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        min-height: calc(var(--form-sm) - var(--unit-md));
        gap: var(--unit-md);
        color: var(--color-secondary);
        font-size: calc(var(--font-size-md) + 1px);
      }
    }
  }

  .package-card-body {
    display: flex;
    flex-direction: column;
    padding: 0px var(--unit-lg);
    gap: var(--unit-md);
    .package-card-environments {
      display: flex;
      align-items: center;
      gap: var(--unit-md);
      .package-card-environment {
        display: flex;
        align-items: center;
      }
    }
    .package-card-footer {
      display: flex;
      margin-top: 0px;
      font-size: var(--font-size-sm);
      color: var(--color-disabled);
      gap: var(--unit-md);
      flex-wrap: wrap;
      align-items: center;
      padding-bottom: var(--unit-sm);
      margin-right: calc(-1.5 * var(--unit-md));
      .package-card-info {
        display: flex;
        flex-direction: column;
        .package-card-necessary {
          display: flex;
          align-items: center;
          gap: var(--unit-sm);
          flex-wrap: wrap;
          min-height: var(--form-sm);
        }
      }
      .package-card-name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &[data-version="development"],
        &[data-version="staging"] {
          color: var(--orange-dark);
        }
      }
      .package-card-actions {
        display: flex;
        align-items: flex-end;
        flex-grow: 1;
        justify-content: flex-end;
        flex-wrap: wrap;
        gap: var(--unit-sm);
      }
    }
  }

}