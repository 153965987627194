[data-component="ChatMessage"] {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: var(--unit-md) 0px;
  margin-top: var(--unit-lg);
  animation: message-pop-in 0.2s ease-in-out;
  // &:not([data-is-app-response="true"]) {
  //   .chat-body {
  //     flex-direction: row-reverse;
  //     .chat-author {
  //       flex-direction: row-reverse;
  //     }
  //   }
  // }
  .chat-body {
    display: flex;
    font-size: var(--font-size-md);
    color: var(--color-secondary);
    padding-right: var(--unit-md);

    .chat-avatar {
      position: relative;
      display: flex !important;
      border-radius: 1000px;
      overflow: hidden;
      font-weight: var(--font-weight-bold);
      color: var(--white);
      width: var(--unit-xl);
      height: var(--unit-xl);
      border-radius: 1000px;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      margin: 0px var(--unit-lg);
      margin-top: var(--unit);
      background: var(--green-main);
      &.app {
        background: var(--blue-dark);
      }
      &.image {
        background: none;
      }
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        border: none;
      }
    }

    .chat-content {
      display: flex !important;
      flex-direction: column;
      overflow: hidden;
      position: relative;

      .chat-typing {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: var(--unit-sm);
        width: calc(42px * 1.4);
        height: 42px; // TODO: Make this dynamic, currently height of 1 line of text + author info
        background: var(--background-tertiary);
        border-radius: 1000px;
        padding: 0px var(--unit-md);
        .dot {
          width: var(--unit-sm);
          height: var(--unit-sm);
          border-radius: 1000px;
          background: var(--color-secondary);
          &:nth-child(1) {
            animation: typing-dot 1.5s linear infinite 0s;
          }
          &:nth-child(2) {
            animation: typing-dot 1.5s linear infinite 0.25s;
          }
          &:nth-child(3) {
            animation: typing-dot 1.5s linear infinite 0.5s;
          }
        }
      }

      .chat-author {
        display: flex !important;
        flex-wrap: wrap;
        align-items: center;
        .chat-author-name {
          color: var(--color-primary);
          display: flex !important;
          align-items: center;
          gap: var(--unit-md);
          margin-right: var(--unit-md);
          .app {
            background: var(--blue-dark);
            padding: 0px var(--unit-sm);
            border-radius: var(--radius);
            font-weight: var(--font-weight-bold);
            font-size: var(--font-size-xs);
          }
        }
        .chat-time {
          color: var(--color-disabled);
          font-size: var(--font-size-sm);
          margin-right: var(--unit-md);
        }
        .chat-notification {
          position: relative;
          display: flex;
          align-items: center;
          gap: var(--unit-sm);
          color: var(--color-disabled);
          font-size: var(--font-size-sm);
          border-radius: var(--radius-lg);
          margin: var(--unit-sm) 0px;
          margin-right: var(--unit-md);
          svg {
            color: var(--orange-main);
            width: var(--unit-md);
            height: var(--unit-md);
          }
          &.blocked {
            svg {
              color: var(--red-main);
            }
          }
        }
      }
      .chat-message {
        overflow: auto;
        display: flex;
        flex-direction: column;
        line-height: var(--line-height-lg);
        white-space: pre-wrap;
        word-break: break-word;

        & > p {
          margin: 0px;
          line-height: 1.6em;
          &:last-child {
            margin-bottom: 0px;
          }
        }

        pre:has(code) {
          display: flex;
          margin: 0px;
          padding: 0px;
          code {
            margin: 0px;
            padding: var(--unit-sm)var(--unit-md);
          }
        }

        code {
          font-family: var(--font-family-monospace);
          font-size: var(--font-size-sm);
          background: var(--background-secondary);
          border-radius: var(--radius-lg);
          padding: var(--unit) var(--unit-md);
          border: 1px solid var(--background-tertiary);
          color: var(--color-primary);
          margin: 0px var(--unit-sm);
        }

        ul, ol {
          margin: var(--unit-md) 0px;
          padding: 0px;
          padding-left: var(--unit-xl);
          line-height: 0px;
          li {
            margin: 0px;
            padding: 0px;
            &:not(:last-child) {
              margin-bottom: var(--unit-sm);
            }
          }
        }

        strong {
          font-weight: var(--font-weight-medium);
          color: var(--color-primary);
        }

        hr {
          max-width: 80%;
          min-width: calc(var(--unit-xl) * 4);
          border: none;
          border-top: 1px solid var(--outline-default);
          margin: var(--unit-lg) auto;
        }

        table {
          border: var(--unit-sm) solid var(--background-primary);
          background: var(--background-primary);
          border-radius: var(--radius-lg);
          border-spacing: 0px;
          margin: 0px;
          margin-right: auto;
          tr {
            td, th {
              padding: var(--unit-sm) var(--unit-lg);
              border: 1px solid var(--background-tertiary);
              &:not(:last-child) {
                border-right: none;
              }
            }
            &:not(:last-child) {
              td, th {
                border-bottom: none;
              }
            }
          }
          thead {
            tr {
              background: var(--background-tertiary);
              &:first-child {
                td, th {
                  text-align: left;
                  &:first-child {
                    border-top-left-radius: var(--radius);
                  }
                  &:last-child {
                    border-top-right-radius: var(--radius);
                  }
                }
              }
            }
          }
          tbody {
            tr {
              &:last-child {
                td, th {
                  &:first-child {
                    border-bottom-left-radius: var(--radius);
                  }
                  &:last-child {
                    border-bottom-right-radius: var(--radius);
                  }
                }
              }
            }
          }
        }

        .chat-notification {
          display: block;
          border-radius: var(--radius-lg);
          margin-top: var(--unit-md);
          font-size: var(--font-size-sm);
          color: var(--color-disabled);
        }
      }
      .function {
        display: inline-flex;
        position: relative;
        flex-direction: column;
        margin: var(--unit-sm) 0px;
        font-size: var(--font-size-md);
        overflow: hidden;
        color: var(--color-disabled);
        border-left: 4px solid var(--background-input);

        .function-header {
          display: flex !important;
          height: var(--form-sm);
          align-items: center;
          padding: 0px var(--unit-md);
          cursor: pointer;
          gap: var(--unit-md);
          overflow: hidden;
          border-top-right-radius: var(--radius-lg);
          border-bottom-right-radius: var(--radius-lg);
          transition: background 0.1s ease-in-out;
          min-width: 300px;
          max-width: 100%;
          // background: var(--background-input);
          transition: color 0.1s ease-in-out,
            background 0.1s ease-in-out;
          &:hover {
            color: var(--color-primary);
            background: var(--background-input);
          }

          .expand-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: var(--unit-sm);
            svg {
              width: var(--unit-lg);
              height: var(--unit-lg);
            }
          }

          .function-name {
            display: flex !important;
            align-items: center;
            overflow: hidden;
            gap: var(--unit-md);
            a {
              display: inline-block;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            svg {
              flex-shrink: 0;
              width: calc(var(--unit-md) + var(--unit-sm));
              height: calc(var(--unit-md) + var(--unit-sm));
              &.tool {
                color: var(--color-disabled);
              }
              &.success {
                color: var(--green-light);
              }
              &.error {
                color: var(--red-main);
              }
            }
          }
        }

        .function-body {
          padding-left: var(--unit-md);
          margin-top: var(--unit-sm);
          .function-body-info {
            display: flex !important;
            align-items: center;
            overflow: hidden;
            gap: var(--unit-md);
            padding-right: var(--unit-md);
            a {
              display: inline-block;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            svg {
              flex-shrink: 0;
              width: calc(var(--unit-md) + var(--unit-sm));
              height: calc(var(--unit-md) + var(--unit-sm));
            }
          }
        }
      }
      .sql {
        overflow: auto;
        margin-bottom: var(--unit-sm);
        background: var(--background-primary);
        border: 1px solid var(--outline-default);
        border-radius: var(--radius-lg);
        font-size: var(--font-size-xs);

        &:hover {
          border-color: var(--outline-hover);
        }
        
        .query-container {
          display: flex;
          align-items: center;
          padding: var(--unit-md);
          gap: var(--unit-md);
          cursor: pointer;
          min-height: var(--form-sm);

          & > svg {
            flex-shrink: 0;
            width: calc(var(--unit-md) + var(--unit-sm));
            height: calc(var(--unit-md) + var(--unit-sm));
            color: var(--green-main);
          }

          .expand-icon {
            color: var(--color-secondary);
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .query {
            margin: 0;
            padding: 0;
            background: none;
            flex: 1;
            font-family: var(--font-family-monospace);
            white-space: pre-wrap;
            word-break: break-word;
          }
        }

        .result {
          margin: var(--unit-md);
          background: var(--background-primary);
          margin-top: var(--unit-sm);
          padding: var(--unit-md);
          border-radius: var(--radius-lg);
          white-space: pre-wrap;
          word-break: break-word;
        }
      }
    }
  }
}